<script>
    import Card from '@/components/Card'

    export default {
        components: {
            Card,
        },

        mounted() {
            this.$root.restrictCountries('*')
            this.$store.commit('mainPage', {
                current: this.$t('Users'),
                stack: [{
                    'path': '/users',
                    'title': this.$t('Users'),
                }]
            })
        }
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <card class="is-card-table has-pagination has-bottom-left-hidden">
                <bg-table
                        :label="$t('Users')"
                        view="details?for=User&amp;id=${Id}"
                        :remove="{ id: 'Id', render: row => { return row.FirstName + ' ' + row.LastName }, desc: $t('User') }"
                        :create="$root.Storage.get('user') && $root.Storage.get('user').roleShortcut !== 'admin' ? '' : 'create?for=User'"
                        path="user"
                        object="User"
                        :noChanges="true"
                        :filter="$root.Storage.get('user') && $root.Storage.get('user').roleShortcut !== 'admin' ? {Id: $root.Storage.get('user').id} : {}"
                        v-slot="data">

                    <bg-column :label="$t('Username')" sort="Username">{{ data.row.Username }}</bg-column>
                    <bg-column :label="$t('First name')" sort="FirstName">{{ data.row.FirstName }}</bg-column>
                    <bg-column :label="$t('Last name')" sort="LastName">{{ data.row.LastName }}</bg-column>
                    <bg-column :label="$t('Email')" sort="Email">{{ data.row.Email }}</bg-column>
                    <bg-column :label="$t('Role')" sort="UserRole" v-if="data.row.UserRole">
                        {{ $root.translation(data.row.UserRole.UserRoleI18ns).Name }}
                    </bg-column>
                    <bg-column :label="$t('MFA2')" sort="Mfa2Created">{{ data.row.Mfa2Created ? $t('Yes') : '-' }}</bg-column>
                    <bg-column :label="$t('Role')" sort="UserRole" v-if="!data.row.UserRole">-</bg-column>
                </bg-table>
            </card>
        </div>
    </section>
</template>
