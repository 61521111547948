import moment from 'moment/moment';

export default {
    filters: {
        date: function (value) {
            if (!value) {
                return '-';
            }

            return moment(value).format('DD.MM.YYYY HH:mm:ss')
        },
        dateUnix: function (value) {
            if (!value) {
                return '-';
            }

            return moment.unix(value).format('DD.MM.YYYY HH:mm:ss')
        },
    }
}
