<script>
    export default {
        props: {
            fetched: {},
            create: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                UserRole: [],
                user: null,
                qrCode: '#',
                secret: '',
                isMfa2Created: false,
            }
        },

        async created() {
            await this.$root.fetch('user-role').then(response => {
                this.UserRole = response.UserRole
            })
        },

        mounted() {
            this.user = this.$root.Storage.get('user')
            if (!this.create) {
                this.isMfa2Created = this.fetched.Mfa2Created
            }
        },

        methods: {
            beforeSend(content, call) {
                return [content, this.$root.stringPlaceholders(call, content)]
            },
            createMfa2() {
                this.$root.fetch('user', {action: 'getOtpQrCode'}).then(response => {
                    if (typeof response.qrCode !== 'undefined') {
                        this.qrCode = response.qrCode
                        this.secret = response.secret
                    }
                })
            },
            resetMfa2() {
                this.$root.post('user/' + this.fetched.Id, {action: 'resetMfa2'}).then(() => {
                    this.isMfa2Created = false
                })
            },
            mfaVerified(verified) {
                this.isMfa2Created = !!verified
            },
            copySecretToClipboard() {
                navigator.clipboard.writeText(this.secret)
            },
        },
    }
</script>

<template>
    <div>
        <bg-form v-if="create" :button="$t('Create user')" :beforeSend="'beforeSend'" call="user"
                 @afterSend="$emit('afterSend')">
            <bg-form-input :label="$t('First name')" name="FirstName" :size="3"/>
            <bg-form-input :label="$t('Last name')" name="LastName" :size="3"/>
            <bg-form-input :label="$t('Email')" name="Email" :size="3"/>
            <bg-form-input :label="$t('NEOS username')" name="NeosUsername" :size="3"/>
            <bg-form-input :label="$t('Username')" name="Username" :size="3"/>
            <bg-form-input :label="$t('Password (min. 5 characters)')" name="Password" type="password" :size="3"/>
            <bg-form-select
                    :size="3"
                    :label="$t('Role')"
                    name="UserRoleId"
                    :options="UserRole"
                    :option-id="option => option.Id"
                    :placeholder="$t('Please select...')"
                    :option-label="option => $root.translation(option.UserRoleI18ns, {}, 'de_AT').Name"/>
            <bg-form-input :label="$t('ID')" :size="3" :prefill="$t('Will be created automatically')"/>
        </bg-form>

        <div v-else>
            <bg-form :button="$t('Save changes')" :beforeSend="'beforeSend'" call="user/${__ID}"
                     @afterSend="$emit('afterSend')">
                <bg-form-input :label="$t('First name')" name="FirstName" :size="3" :prefill="fetched.FirstName"/>
                <bg-form-input :label="$t('Last name')" name="LastName" :size="3" :prefill="fetched.LastName"/>
                <bg-form-input :label="$t('Email')" name="Email" :size="3" :prefill="fetched.Email"/>
                <bg-form-input :label="$t('NEOS username')" :name="fetched.UserRole.Shortcut === 'admin' ? 'NeosUsername' : ''" :size="3" :prefill="fetched.NeosUsername"/>
                <bg-form-input :label="$t('Username')" :name="fetched.UserRole.Shortcut === 'admin' ? 'Username' : ''" :size="3" :prefill="fetched.Username"/>
                <bg-form-input :label="$t('Password (min. 5 characters)')" name="Password" :password="true" :size="3"/>
                <bg-form-select
                        v-if="fetched.UserRole.Shortcut === 'admin'"
                        :size="3"
                        :label="$t('Role')"
                        name="UserRoleId"
                        :options="UserRole"
                        :prefill="fetched.UserRoleId"
                        :option-id="option => option.Id"
                        :placeholder="$t('Please select...')"
                        :option-label="option => $root.translation(option.UserRoleI18ns, {}, 'de_AT').Name"/>
                <bg-form-input :label="$t('ID')" :size="3" :prefill="fetched.Id"/>
            </bg-form>

            <hr>
            <h4 class="subtitle is-4">{{ $t('MFA2 configuration') }}</h4>
            <p v-if="!isMfa2Created && user && user.id !== fetched.Id">
                {{ $t('To configure MFA2 you have to be logged in with this user.') }}
            </p>
            <div v-else-if="!isMfa2Created && user && user.id === fetched.Id">
                <div v-if="qrCode !== '#'">
                    <img :src="qrCode" width="400" height="400" alt="">
                    <div>
                        <bg-form-input label="Secret" :readonly="true" prefill="******************">
                            <button class="button is-input-copy" @click.prevent="copySecretToClipboard">
                                <b-icon icon="content-copy" custom-size="default"/>
                            </button>
                        </bg-form-input>
                    </div>
                    <br>
                    <bg-otp-input @mfaVerified="mfaVerified"/>
                </div>
                <button v-if="qrCode === '#'"
                        @click="createMfa2()"
                        class="button is-primary">
                    {{ $t('Create MFA2') }}
                </button>
            </div>
            <div v-else-if="isMfa2Created">
                <p>{{ $t('MFA2 is configured for this account. If the user still cannot login try to reset the token.') }}</p>
                <br>
                <button @click="resetMfa2()"
                        class="button is-danger">
                    {{ $t('Reset MFA2') }}
                </button>
            </div>
        </div>
    </div>
</template>
