<script>
import Filters from '../../filters'
import Helpers from '../../helpers'

export default {
    props: {
        commandProcessing: {
            type: Object,
        },
        neosStatus: {
            type: Object,
        },
        persistentStatus: {
            type: Object,
        },
        uploadsStatus: {
            type: Object,
        },
        dbStatus: {
            type: Object,
        },
        sapStatus: {
            type: Object,
        },
        importRequested: {
            type: Boolean,
        },
    },
    mixins: [Filters, Helpers],
    data() {
        return {
            exportLoading: false,
        }
    },
    methods: {
        exportData() {
            this.$parent.resetMessage()
            if (confirm(this.$t('Are you sure you want to do this action?'))) {
                this.exportLoading = true
                this.$root.fetch('export')
                    .then((response) => {
                        this.exportLoading = false
                        if (response) {
                            this.$parent.errorMessage = response
                        } else {
                            this.$parent.successMessage = this.$t('Export was successful.')
                        }
                        setTimeout(this.$parent.resetMessage, 4000)
                    })
            }
        },
        isAnyStatusActive() {
            return !!(this.sapStatus.start || this.sapStatus.processing || this.uploadsStatus.start ||
                this.uploadsStatus.processing || this.dbStatus.start || this.dbStatus.processing ||
                this.neosStatus.start || this.neosStatus.processing || this.persistentStatus.start ||
                this.persistentStatus.processing)
        },
        getPlatform() {
            let stage = process.env.NODE_ENV
            if (stage === 'production' && window.location.hostname !== 'data.bg-graspointner.com') {
                stage = 'staging'
            }

            return stage;
        },
    },
}
</script>

<template>
    <div class="columns">
        <div class="column is-6">
            <h4 class="subtitle is-4">
                <span>{{ $t('Export data to the website SQLite database') }}</span>
                <span class="tooltip">
                    <i class="tooltip__arrow mdi mdi-account-question"></i>
                    <span class="tooltip__inner">
                        {{ $t('All product data from the Mapping Tool is exported to an SQLite database. This file is then transferred to the website. To make the updated data visible, the MT caches must be cleared (rebuilding is optional).') }}
                    </span>
                </span>
            </h4>
            <ul class="is-list is-list--disc">
                <li>{{ $t('Exports data from MySql to SQLite database') }}</li>
                <li>{{ $t('Copies generated file to the Neos web project') }}</li>
                <li style="color: rgb(211,0,0);">{{ $t('IMPORTANT: you have to clear SQLite-Cache of neos project after this action') }}</li>
            </ul>
            <button @click="exportData" class="button is-primary" :disabled="exportLoading">
                <span v-if="!exportLoading">{{ $t('Perform') }}</span>
                <span v-if="exportLoading">{{ $t('Is running') }}...</span>
            </button>
        </div>
        <div class="column is-6">
            <template v-if="getPlatform() && getPlatform() !== 'production'">
                <h4 class="subtitle is-4">
                    <span>{{ $t('Copy media & documents from live system') }}</span>
                    <span class="tooltip">
                        <i class="tooltip__arrow mdi mdi-account-question"></i>
                        <span class="tooltip__inner">
                            {{ $t('All media from the live environment is copied to the staging environment. This action should be performed after the `Copy database from live system` action to ensure an identical state to the live system.') }}
                        </span>
                    </span>
                </h4>
                <ul class="is-list is-list--disc">
                    <li v-if="uploadsStatus.start">{{ $t('Started at') }}: {{ uploadsStatus.start | dateUnix }}</li>
                    <li v-if="uploadsStatus.processing">{{ $t('Processing since') }}: {{ uploadsStatus.processing | dateUnix }}</li>
                    <li v-if="uploadsStatus.finished">{{ $t('Last finished at') }}: {{ uploadsStatus.finished | dateUnix }}</li>
                </ul>
                <button @click="$emit('execute', 'uploads')" class="button is-primary" :disabled="commandProcessing.uploads || isAnyStatusActive() || importRequested">
                    <span>{{ $t('Perform') }}</span>
                </button>
                <hr>

                <h4 class="subtitle is-4">
                    <span>{{ $t('Copy MT-database from live system') }}</span>
                    <span class="tooltip">
                        <i class="tooltip__arrow mdi mdi-account-question"></i>
                        <span class="tooltip__inner">
                            {{ $t('The MT database from the live environment is copied to the staging environment, fully replacing the existing data. This action should be performed together with the `Copy media & documents from the live system` action. Warning: All changes in the staging environment (data and structure) will be overwritten.') }}
                        </span>
                    </span>
                </h4>
                <ul class="is-list is-list--disc">
                    <li v-if="dbStatus.start">{{ $t('Started at') }}: {{ dbStatus.start | dateUnix }}</li>
                    <li v-if="dbStatus.processing">{{ $t('Processing since') }}: {{ dbStatus.processing | dateUnix }}</li>
                    <li v-if="dbStatus.finished">{{ $t('Last finished at') }}: {{ dbStatus.finished | dateUnix }}</li>
                </ul>
                <button @click="$emit('execute', 'db')" class="button is-primary" :disabled="commandProcessing.db || isAnyStatusActive() || importRequested">
                    <span>{{ $t('Perform') }}</span>
                </button>
                <hr>

                <h4 class="subtitle is-4">
                    <span>{{ $t('Copy SAP-Database from live system') }}</span>
                    <span class="tooltip">
                        <i class="tooltip__arrow mdi mdi-account-question"></i>
                        <span class="tooltip__inner">
                            {{ $t('The SAP database from the live environment is copied to the staging environment, replacing the existing one.') }}
                        </span>
                    </span>
                </h4>
                <ul class="is-list is-list--disc">
                    <li v-if="sapStatus.start">{{ $t('Started at') }}: {{ sapStatus.start | dateUnix }}</li>
                    <li v-if="sapStatus.processing">{{ $t('Processing since') }}: {{ sapStatus.processing | dateUnix }}</li>
                    <li v-if="sapStatus.finished">{{ $t('Last finished at') }}: {{ sapStatus.finished | dateUnix }}</li>
                </ul>
                <button @click="$emit('execute', 'sap')" class="button is-primary" :disabled="commandProcessing.sap || isAnyStatusActive() || importRequested">
                    <span>{{ $t('Perform') }}</span>
                </button>
            </template>
        </div>
    </div>
</template>
