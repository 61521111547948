<script>
import Filters from '../../filters'
import Helpers from '../../helpers';

export default {
    props: {
        availableLanguages: {
            type: Array,
        },
        importRequested: {
            type: Boolean,
        },
    },
    mixins: [Filters, Helpers],
    data() {
        return {
            languagesSelection: '',
            languages: [],
        }
    },
    computed: {
        isAnySapProcessing() {
            for (let key in this.availableLanguages) {
                const item = this.availableLanguages[key]
                if (item.last_sap_upload_started_t > item.last_sap_upload_finished_t || item.last_sap_upload_requested_t > item.last_sap_upload_finished_t) {
                    return true
                }
            }

            return false
        },
    },
    methods: {
        requestData() {
            this.$parent.resetMessage()
            let isValid = true
            if (this.languagesSelection === 'selection' && !this.languages.length) {
                this.$parent.errorMessage = this.$t('Please select at least one language.')
                setTimeout(this.$parent.resetMessage, 4000)
                isValid = false
            }

            if (isValid && confirm(this.$t('Are you sure you want to do this action?'))) {
                this.$root.post('admin', {
                    action: 'requestSap',
                    languages: this.languages.length ? this.languages : ['*'],
                }).then((response) => {
                    if (response.sapStatus === 'success') {
                        this.$parent.successMessage = response.sapMessage
                    } else {
                        this.$parent.errorMessage = response.sapMessage
                    }
                    this.$parent.fetchData()
                    setTimeout(this.$parent.resetMessage, 4000)
                })
            }
        },
    },
}
</script>

<template>
    <div class="columns">
        <div class="column is-6">
            <h4 class="subtitle is-4">
                <span>{{ $t('Update SAP data') }}</span>
                <span class="tooltip">
                    <i class="tooltip__arrow mdi mdi-account-question"></i>
                    <span class="tooltip__inner">
                        {{ $t('If you only want to update the SAP data in the MySQL database to check whether it has been imported correctly, you can use this action. You can also select individual languages in which the data needs to be updated. All other actions should be started via the import function in the header.') }}
                    </span>
                </span>
            </h4>
            <b-field v-if="!importRequested && !isAnySapProcessing" :addons="false" :label="$t('Languages import')">
                <div class="control is-clearfix">
                    <b-radio v-model="languagesSelection"
                             name="languagesSelection"
                             native-value="">
                        {{ $t('All languages') }}
                    </b-radio>
                </div>
                <div class="control is-clearfix">
                    <b-radio v-model="languagesSelection"
                             name="languagesSelection"
                             native-value="selection">
                        {{ $t('Language selection') }}
                    </b-radio>
                </div>
                <div class="control is-clearfix" v-if="languagesSelection === 'selection'">
                    <b-checkbox v-model="languages"
                                :native-value="availableLanguage.locale"
                                v-for="(availableLanguage, key) in availableLanguages"
                                :key="key">
                        {{ availableLanguage.locale }}&nbsp;&nbsp;
                    </b-checkbox>
                </div>
            </b-field>
            <button v-if="!importRequested && !isAnySapProcessing" @click="requestData" class="button is-primary">
                <span>{{ $t('Start') }}</span>
            </button>
            <p v-if="importRequested || isAnySapProcessing">{{ $t('SAP data update is already request or running.') }}</p>

            <hr>

            <h4 class="subtitle is-4">
                <span>{{ $t('SAP languages') }}</span>
                <span class="tooltip">
                    <i class="tooltip__arrow mdi mdi-account-question"></i>
                    <span class="tooltip__inner">
                        {{ $t('This is an overview of the imports from SAP: when they were last executed, whether there are any open requests in the system, or if a request is currently being processed.') }}
                    </span>
                </span>
            </h4>
            <template v-for="(availableLanguage, key) in availableLanguages">
                <div v-if="availableLanguage.last_sap_upload_started_t > availableLanguage.last_sap_upload_finished_t" :key="key">
                    <strong>{{ availableLanguage.locale }} - </strong> <span style="color:#9ED300;">{{ $t('running since') }}</span> {{ availableLanguage.last_sap_upload_started | date }} ({{ $t('last finish') }}: {{ availableLanguage.last_sap_upload_finished | date }})
                </div>
                <div v-else-if="availableLanguage.last_sap_upload_requested_t > availableLanguage.last_sap_upload_finished_t" :key="key">
                    <strong>{{ availableLanguage.locale }} - </strong> <span style="color:#9ED300;">{{ $t('requested since') }}</span> {{ availableLanguage.last_sap_upload_requested | date }} ({{ $t('last finish') }}: {{ availableLanguage.last_sap_upload_finished | date }})
                </div>
                <div v-else :key="key">
                    <strong>{{ availableLanguage.locale }} - </strong> {{ $t('last finish') }}: {{ availableLanguage.last_sap_upload_finished | date }} ({{ $t('Duration') }}: {{ getDuration(availableLanguage.last_sap_upload_started, availableLanguage.last_sap_upload_finished) }})
                </div>
            </template>
        </div>
    </div>
</template>
