<template>
    <b-field :label="$t('Provide the authentication token')">
        <otp-input
            class="mfa2-input"
            ref="otpInput"
            input-classes="otp-input"
            separator="-"
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnMfaChange"
            @on-complete="handleOnMfaComplete"
        />
    </b-field>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
    components: {OtpInput},
    methods: {
        handleOnMfaComplete(value) {
            this.$root.post('user', {
                action: 'verifyOtpToken',
                token: value,
            }).then(response => {
                this.$emit('mfaVerified', typeof response.isValid !== 'undefined' && response.isValid ? response.newJwtToken : '')
            })
        },

        handleOnMfaChange() {
            // do nothing
        },
    },
}
</script>
