<script>
import Filters from '../filters'
import Helpers from '../helpers';

export default {
    props: {
        availableLanguages: {
            type: Array,
        },
        isAnyImportRunning: {
            type: Boolean,
        },
        isImportRequested: {
            type: Boolean,
        },
        importRequestProcessing: {
            type: Boolean,
        },
        userEmail: {
            type: String,
        },
    },
    mixins: [Filters, Helpers],
    data() {
        return {
            expertMode: false,
            languagesSelection: '',
            languages: [],
            importOptions: {
                sap: '',
                publish: '',
                products: '',
                sqlite: '',
                reSqlite: '',
                redis: '',
                email: '',
            },
        }
    },
    methods: {
        importData() {
            let isValid = true
            if (this.languagesSelection === 'selection' && !this.languages.length) {
                alert(this.$t('Please select at least one language.'))
                isValid = false
            }

            if (isValid && confirm(this.$t('Are you sure you want to do this action?'))) {
                this.$root.post('admin', {
                    action: 'import',
                    languages: this.languages,
                    params: this.importOptions,
                }).then((response) => {
                    if (response.success) {
                        this.importOptions.sap = ''
                        this.importOptions.publish = ''
                        this.importOptions.products = ''
                        this.importOptions.sqlite = ''
                        this.importOptions.reSqlite = ''
                        this.importOptions.redis = ''
                        this.importOptions.email = ''
                        this.languages = []
                        this.languagesSelection = ''
                        this.$emit('importStarted')
                    } else {
                        alert(response.message);
                    }
                })
            }
        },
    },
    watch: {
        expertMode(newVal) {
            if (!newVal) {
                this.importOptions.sap = ''
                this.importOptions.publish = ''
                this.importOptions.products = ''
                this.importOptions.sqlite = ''
                this.importOptions.reSqlite = ''
                this.importOptions.redis = ''
            }
        }
    }
}
</script>

<template>
    <div class="card is-card-table">
        <div class="card-content">
            <div class="columns bg-upload__grid">
                <div class="column">
                    <div class="level">
                        <div class="level-left">
                            <p class="is-size-5">
                                <strong>{{ $t('Update and renew data') }}</strong>
                                <span class="tooltip">
                                    <i class="tooltip__arrow mdi mdi-account-question"></i>
                                    <span class="tooltip__inner">
                                        {{ $t('All data from SAP is imported into the MySQL database here. During this process, the data is transformed into a different structure to be used by the website. In the settings, you can either synchronize all languages or only selected ones.') }}
                                    </span>
                                </span>
                            </p>
                        </div>
                        <div class="level-right" v-if="!isAnyImportRunning && !isImportRequested && !importRequestProcessing">
                            <div class="field">
                                <b-switch v-model="expertMode" type="is-success" size="is-medium">
                                    <span style="font-size: 16px">{{ $t('Expert mode') }}</span>
                                </b-switch>
                            </div>
                        </div>
                    </div>

                    <div v-if="!isAnyImportRunning && !isImportRequested && !importRequestProcessing">
                        <div class="columns">
                            <div class="column is-half">
                                <b-field :addons="false" :label="$t('Languages import')">
                                    <div class="control is-clearfix">
                                        <b-radio v-model="languagesSelection"
                                                 name="languagesSelection"
                                                 native-value="">
                                            {{ $t('All languages') }}
                                        </b-radio>
                                    </div>
                                    <div class="control is-clearfix">
                                        <b-radio v-model="languagesSelection"
                                                 name="languagesSelection"
                                                 native-value="selection">
                                            {{ $t('Language selection') }}
                                        </b-radio>
                                    </div>
                                    <div class="control is-clearfix" v-if="languagesSelection === 'selection'">
                                        <b-checkbox v-model="languages"
                                                    :native-value="availableLanguage.locale"
                                                    v-for="(availableLanguage, key) in availableLanguages"
                                                    :key="key">
                                            {{ availableLanguage.locale }}&nbsp;&nbsp;
                                        </b-checkbox>
                                    </div>
                                </b-field>
                                <b-field :addons="false" :label="$t('Additional options')">
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox true-value="1"
                                                    false-value="0"
                                                    v-model="importOptions.sap"
                                                    :aria-checked="false">
                                            <span>{{ $t('Update SAP data') }}</span>
                                            <span class="tooltip">
                                                <i class="tooltip__arrow mdi mdi-account-question"></i>
                                                <span class="tooltip__inner">
                                                    {{ $t('Requests the latest data from the SAP system for the selected languages, uploads it, and imports the data from it.') }}
                                                </span>
                                            </span>
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox true-value="1"
                                                    false-value="0"
                                                    v-model="importOptions.publish"
                                                    :aria-checked="false">
                                            <span>{{ $t('Publish on the website after completion') }}</span>
                                            <span class="tooltip">
                                                <i class="tooltip__arrow mdi mdi-account-question"></i>
                                                <span class="tooltip__inner">
                                                    {{ $t('After the import is completed, the data is published directly on the website.') }}
                                                </span>
                                            </span>
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox true-value="1"
                                                    false-value="0"
                                                    v-model="importOptions.products"
                                                    :aria-checked="false">
                                            <span>{{ $t('Update product page structure') }}</span>
                                            <span class="tooltip">
                                                <i class="tooltip__arrow mdi mdi-account-question"></i>
                                                <span class="tooltip__inner">
                                                    {{ $t('After the import is completed, new product pages are automatically created in the Neos project. Note: The sub-brand must be correctly created in Neos beforehand.') }}
                                                </span>
                                            </span>
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox true-value="1"
                                                    false-value="0"
                                                    v-model="importOptions.sqlite"
                                                    :aria-checked="false">
                                            <span>{{ $t('Clear product caches') }}</span>
                                            <span class="tooltip">
                                                <i class="tooltip__arrow mdi mdi-account-question"></i>
                                                <span class="tooltip__inner">
                                                    {{ $t('Clears the cache entries for modified product data. Note: This only includes URLs known to the caching system.') }}
                                                </span>
                                            </span>
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox true-value="1"
                                                    false-value="0"
                                                    v-model="importOptions.reSqlite"
                                                    :aria-checked="false">
                                            <span>{{ $t('Rebuild product caches') }}</span>
                                            <span class="tooltip">
                                                <i class="tooltip__arrow mdi mdi-account-question"></i>
                                                <span class="tooltip__inner">
                                                    {{ $t('Refreshes the cache entries for modified product data. Note: This only includes URLs known to the caching system.') }}
                                                </span>
                                            </span>
                                        </b-checkbox>
                                    </div>
                                    <div v-if="expertMode" class="control is-clearfix">
                                        <b-checkbox true-value="1"
                                                    false-value="0"
                                                    v-model="importOptions.redis"
                                                    :aria-checked="false">
                                            <span>{{ $t('Clear REDIS cache') }}</span>
                                            <span class="tooltip">
                                                <i class="tooltip__arrow mdi mdi-account-question"></i>
                                                <span class="tooltip__inner">
                                                    {{ $t('After the import is completed, the REDIS cache of the website is cleared.') }}
                                                </span>
                                            </span>
                                        </b-checkbox>
                                    </div>
                                    <div class="control is-clearfix">
                                        <b-checkbox :true-value="userEmail"
                                                    v-model="importOptions.email"
                                                    :aria-checked="false">
                                            {{ $t('Notify me via email when completed') }} ({{ userEmail }})
                                        </b-checkbox>
                                    </div>
                                </b-field>
                            </div>
                            <div class="column is-half">
                                <div v-if="expertMode">
                                    <template v-for="(availableLanguage, key) in availableLanguages">
                                        <div v-if="availableLanguage.last_mt_import_started_t > availableLanguage.last_mt_import_finished_t" :key="key">
                                            <strong>{{ availableLanguage.locale }} - </strong> <span style="color:#9ED300;">{{ $t('running since') }}</span> {{ availableLanguage.last_mt_import_started | date }} ({{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }})
                                        </div>
                                        <div v-else :key="key">
                                            <strong>{{ availableLanguage.locale }} - </strong> {{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }} ({{ $t('Duration') }}: {{ getDuration(availableLanguage.last_mt_import_started, availableLanguage.last_mt_import_finished) }})
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <button @click="importData" class="button is-primary">
                            <span>{{ $t('Start') }}</span>
                        </button>
                    </div>
                    <div v-else>
                        <p v-if="isImportRequested && !importRequestProcessing">{{ $t('Preparation steps are running. Import will start soon.') }}</p>
                        <p v-else-if="importRequestProcessing && !isAnyImportRunning">{{ $t('Final steps are running. Import will end soon.') }}</p>
                        <div v-else>
                            <p>{{ $t('One or more imports are running right now. You cannot start another one.') }}<br></p>
                            <br>
                            <template v-for="(availableLanguage, key) in availableLanguages">
                                <div v-if="availableLanguage.last_mt_import_started_t > availableLanguage.last_mt_import_finished_t" :key="key">
                                    <strong>{{ availableLanguage.locale }} - </strong> <span style="color:#9ED300;">{{ $t('running since') }}</span> {{ availableLanguage.last_mt_import_started | date }} ({{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }})
                                </div>
                                <div v-else :key="key">
                                    <strong>{{ availableLanguage.locale }} - </strong> {{ $t('last finish') }}: {{ availableLanguage.last_mt_import_finished | date }} ({{ $t('Duration') }}: {{ getDuration(availableLanguage.last_mt_import_started, availableLanguage.last_mt_import_finished) }})
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
