import moment from 'moment/moment';

export default {
    methods: {
        getDuration: function (start, end) {
            if (!end) {
                return '-';
            }

            let duration = moment.duration(moment(end).diff(moment(start)));
            return (duration.asMinutes()).toFixed(2) + 'm';
        },
    }
}
