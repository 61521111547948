<template>
    <div ref="menuContainer" class="menu-container" :class="{'is-bottom':isBottom}" @mouseenter="psUpdate">
        <template v-for="(menuIterated, index) in menuListComputed">
            <p v-if="typeof menuIterated === 'string'" :key="(index+1)" class="menu-label">
                {{ menuIterated }}
            </p>

            <aside-menu-list-plain
                    v-else
                    @menu-click="menuClick"
                    :hasTooltips="hasTooltips"
                    :indexKeyPrefix="containerIndexPrefix + '-' + (index + 1)"
                    :key="(index+1)"
                    :menuList="menuIterated"
                    :hasDropdownEnabled="hasDropdownEnabled"
                    :isUpdateByGlobalActiveState="isUpdateByGlobalActiveState"/>
        </template>

        <ul class="additional-menu" v-if="isAdmin()">
            <li>
                <a @click="goToStatusTool">
                        <span class="icon">
                            <i class="mdi mdi-select-compare is-medium"></i>
                        </span>
                    <span class="menu-item-label">{{ $t('Status tool') }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    //import AsideMenuListPlain from '@/components/AsideMenuListPlain'
    import PerfectScrollbar from 'perfect-scrollbar';

    export default {
        name: "AsideMenuContainer",
        // components: {
        //   AsideMenuListPlain
        // },
        data() {
            return {
                ps: null,
            }
        },
        props: {
            menuList: {
                type: Array,
                default: null,
            },
            isUpdateByGlobalActiveState: {
                type: Boolean,
                default: false,
            },
            hasDropdownEnabled: {
                type: Boolean,
                default: false,
            },
            hasTooltips: {
                type: Boolean,
                default: false,
            },
            isBottom: {
                type: Boolean,
                default: false,
            },
            containerIndexPrefix: {
                type: String,
                default: null,
            }
        },
        mounted() {
            if (!this.isBottom) {
                this.ps = new PerfectScrollbar(this.$refs.menuContainer);
            }
        },
        methods: {
            isAdmin() {
                let user = this.$root.Storage.get('user')

                return user && user.roleShortcut === 'admin'
            },
            goToStatusTool() {
                this.$router.push({name:'status-tool'})
            },
            menuClick(item) {
                this.$emit('menu-click', item)
            },
            psUpdate() {
                if (this.ps)
                    this.ps.update()
            }
        },
        computed: {
            menuListComputed() {
                if (this.menuList)
                    return this.menuList;

                if (this.$store.state.secondaryMenu && this.$store.state.secondaryMenu.menu)
                    return this.$store.state.secondaryMenu.menu;

                return [];
            }
        }
    }
</script>
