<script>
    export default {
        props: {
            button: {
                type: String,
                default: '',
            },
            call: {
                type: String,
            },
            beforeSend: {
                type: String,
                default: null
            },
        },

        data () {
            return {
                sending: false,
                sendingMessage: '',
            }
        },

        methods: {
            handleSubmit () {
                this.sending = true

                let call = this.call
                let content = {}

                this.$children.forEach(child => {
                    content = child.appendTo(content)
                })

                if (this.beforeSend !== null) {
                    content = this.$parent.beforeSend(content, call)
                    if (Array.isArray(content)) {
                        [content, call] = content
                    }
                }

                Object.keys(content).forEach(key => {
                    if (key.indexOf('__') === 0) {
                        delete content[key]
                    }
                })

                this.$root.post(call, content).then(response => {
                    this.sending = false

                    if (typeof response == 'object') {
                        response = Object.values(response)

                        while (typeof response === 'object' && Array.isArray(response)) {
                            response = response[0]
                        }

                        if ('Password' in content) {
                            delete content.Password
                        }

                        if ('Password' in response) {
                            delete response.Password
                        }

                        if (!this.$root.objectContains(response, content)) {
                            this.sendingMessage = this.$t('Data could not be saved successfully.<br>Please reload the page.')
                        } else {
                            this.sendingMessage = '✅ ' + this.$t('Data was processed successfully.')
                            this.$emit('afterSend')
                        }
                    }
                }).catch(() => {
                    this.sending = false
                })
            }
        },
    }
</script>

<template>
    <form class="bg-form" @submit.prevent="handleSubmit">

        <div :class="{ 'bg-form__sending': true, 'bg-form__sending--animate': !sending }"></div>

        <div class="columns">
            <slot></slot>
        </div>

        <div class="level" style="align-items: center">
            <div class="level-left">
                <p class="level-item" v-html="sendingMessage"></p>
            </div>
            <div class="level-right">
                <p class="level-item" v-if="button.length > 0"><button class="button is-primary">{{ button }}</button></p>
            </div>
        </div>

    </form>
</template>
