<script>
import BgStateModal from '@/components/BgStateModal'

export default {
    components: {
        BgStateModal: BgStateModal,
    },

    props: {
        path: {
            type: String,
            required: true,
        },
        pathAppend: {
            type: String,
        },
        object: {
            type: String,
            default: undefined,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        siteCount: {
            type: Boolean,
            default: true,
        },
        siteColumns: {
            type: Boolean,
            default: true,
        },
        noChanges: {
            type: Boolean,
            default: false,
        },
        view: {
            type: String,
            default: '',
        },
        remove: {
            type: Object,
            default () {
                return {}
            },
        },
        create: {
            type: String,
            default: '',
        },
        groupBy: {
            type: String,
            default: undefined,
        },
        groupData: {
            type: String,
            default: undefined,
        },
        filter: {
            type: Object,
            default () {
                return {}
            },
        },
        searchValue: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        stateView: {
            type: Boolean,
            default: false,
        },
        collectionView: {
            type: Boolean,
            default: false,
        },
        blankLink: {
            type: Boolean,
            default: false,
        },
        setPerPage: {
            type: Number,
            default: 10,
        }
    },

    data() {
        return {
            query: {
                pagination: {
                    total: 1,
                    current: 1,
                    perPage: this.setPerPage,
                    currentInput: 1,
                },
                sorting: {
                    field: null,
                    direction: null,
                },
                search: {
                    value: '',
                    buffer: null,
                },
            },
            items: [],
            itemsLoading: false,
            liveFilter: this.filter,
            updated: false,
            columnWrapper: {},
            editState: false,
            settings: {
                visibilities: {},
                functions: {},
            },
        }
    },

    watch: {
        searchValue: function(newValue) {
            if (newValue !== this.query.search.value) {
                this.query.search.value = newValue
            }
        },
        path: function (after, before) {
            if (after !== null && before !== null) {
                this.fetch()
            }
        },
    },

    methods: {
        handleSearch(searchValue) {
            this.query.search.value = searchValue

            window.clearTimeout(this.query.search.buffer)
            this.query.search.buffer = setTimeout(() => {
                this.fetch()
            }, 500)
        },

        updateSettings(updateViaPost) {
            let userData = this.$root.Storage.get('user')

            if (!('settings' in userData) || userData.settings == null) {
                userData.settings = {
                    Tables: {}
                }
            }

            userData.settings.Tables[this.path] = JSON.parse(JSON.stringify(this.settings))

            this.$root.Storage.set('user', userData)

            if (updateViaPost === true) {
                this.$forceUpdate()
                this.$root.post(`/user/${userData.id}`, {
                    Settings: JSON.stringify(userData.settings)
                })
            }
        },

        handlePage(newPage) {
            this.query.pagination.current = newPage
            this.fetch()
        },

        handleSort(field, direction) {
            this.query.sorting.field = field
            this.query.sorting.direction = direction
            this.fetch()
        },

        handleCount(perPage) {
            this.query.pagination.perPage = perPage
            this.fetch()
        },

        clickRowView(row) {
            let source = 'source' in this.$route.params ? this.$route.params.source : []
            
            if (this.label.length > 0) {
                let currentQuery = {}

                if (this.query.search.value.length > 0) {
                    currentQuery.s = this.query.search.value
                }

                if (this.query.pagination.current != 1) {
                    currentQuery.p = this.query.pagination.current
                }

                if (this.query.pagination.perPage != 10) {
                    currentQuery.c = this.query.pagination.perPage
                }

                source.push({
                    name: this.$route.name,
                    title: this.label,
                    query: currentQuery,
                })
            }

            let params = {
                for: this.object,
                id: row.Id,
                folder: 0,
                source: source,
                locale: this.$root.settings('locale'),
            }

            this.$router.push({
                name: 're-details',
                params: params
            })

            // this.$router.push(this.$root.stringPlaceholders(this.view, row))
        },

        clickRowRemove(props) {
            let id = props.row[this.remove.id]
            let objectName = this.remove.desc
            let name = ''
            
            if ('translation' in this.remove) {
                name = this.$root.translation(props.row[this.remove.translation])[this.remove.name]
            } else if ('render' in this.remove) {
                name = this.remove.render(props.row)
            } else {
                name = props.row[this.remove.name]
            }

            if (confirm(this.$root.sPrintF(this.$t('Are you sure you want ro remove %ObjectName% `%Name%` (ID: %Id%)?'), {'%ObjectName%': objectName, '%Name%': name, '%Id%': id}))) {
                this.$root.delete(this.path + '/' + id).then(() => {
                    this.itemsLoading = true
                    this.fetch()
                })
            }
        },

        fetch() {
            let params = {
                page: this.query.pagination.current,
                count: this.query.pagination.perPage,
                locale: this.$root.settings('locale'),
            }

            this.$emit('paginated', JSON.parse(JSON.stringify(this.query.pagination)))

            if (this.query.search.value.length > 0) {
                params['search'] = this.query.search.value
            }

            if (this.query.sorting.field != null) {
                params['order[column]'] = this.query.sorting.field
                params['order[dir]'] = this.query.sorting.direction
            }

            if (this.collectionView) {
                params['collection'] = 1
            }

            if (Object.keys(this.liveFilter).length > 0) {
                for (let key in this.liveFilter) {
                    params['filter[' + key + ']'] = this.liveFilter[key]
                }
            }

            // 👨🏼‍💻 temporary fix until testing with real data
            this.items = []
            this.itemsLoading = true

            this.$root.fetch(this.path + (typeof this.pathAppend === 'string' ? this.pathAppend : ''), params).then(response => {
                let temporary = response

                if (typeof this.object !== 'undefined') {
                    this.query.pagination.total = temporary.pagination.total
                    this.query.pagination.current = temporary.pagination.page
                    this.query.pagination.currentInput = temporary.pagination.page

                    if (this.groupBy) {
                        let groupedRows = []
                        let groupedBy = null
                        temporary[this.object].forEach(row => {
                            if (groupedBy != row[this.groupBy]) {
                                groupedBy = row[this.groupBy]
                                groupedRows.push({...row[this.groupData], ...{ __isGroup: true }})
                            }
                            groupedRows.push(row)
                        })
                        this.items = groupedRows
                    } else {
                        this.items = temporary[this.object]
                    }
                }
                this.itemsLoading = false
            }).catch(() => {
                this.items = []
            })
        },

        changePageOnEnter() {
            this.query.pagination.current = this.query.pagination.currentInput
            this.fetch()
        },
    },

    created() {
        let user = this.$root.Storage.get('user')

        this.settings = {
          visibilities: {},
          functions: {},
        }

        if ('settings' in user && user.settings !== null) {
            if ('Tables' in user.settings && this.path in user.settings.Tables) {
                let visibilities = user.settings.Tables[this.path].visibilities
                let functions = user.settings.Tables[this.path].functions

                for (let key in visibilities) {
                  if (typeof visibilities[key] !== 'undefined' && key && key !== 'null') {
                    this.settings.visibilities[key] = visibilities[key]
                  }
                }

                for (let key in functions) {
                  if (typeof functions[key] !== 'undefined' && key && key !== 'null') {
                    this.settings.functions[key] = functions[key]
                  }
                }
            }
        }
    },

    mounted() {
        if (this.searchValue.length > 0) {
            this.query.search.value = this.searchValue
        } else if ('s' in this.$route.query) {
            this.query.search.value = this.$route.query.s
        }

        if ('p' in this.$route.query) {
            this.query.pagination.current = this.$route.query.p
        }

        if ('c' in this.$route.query) {
            this.query.pagination.perPage = this.$route.query.c
        }

        if (this.$root.Storage.get(this.object + '-search')) {
            this.query.search.value = this.$root.Storage.get(this.object + '-search')
        }

        this.fetch()
    },

    updated() {
        let sendSettings = false

        if (!('settings' in this.$root.Storage.get('user')) ||
            this.$root.Storage.get('user').settings === null ||
            !('Tables' in this.$root.Storage.get('user').settings) ||
            !(this.path in this.$root.Storage.get('user').settings.Tables)) {
            sendSettings = true
        }

        this.$root.findChild(this, 'BTable', table => {
            this.settings.functions = this.settings.functions || {}
            let oldSettingsLength = Object.keys(this.settings.functions).length

            if (table.newColumns.length <= 0) {
                return
            }

            table.newColumns.forEach(column => {
                if (column._props.label) {
                    this.settings.functions[column._props.label] = column.$parent.display()
                    if (!(column._props.label in this.settings.visibilities)) {
                      this.settings.visibilities[column._props.label] = true
                    }
                }
            })

            if (oldSettingsLength < Object.keys(this.settings.functions).length) {
                this.$forceUpdate()
                this.$root.findChild(this, 'BgTableToolBar', (child) => {
                    child.$forceUpdate()
                })
            } else if (oldSettingsLength > 0) {
                let newColumns = []

                table.newColumns.forEach(column => {
                    newColumns.push(column.$parent)
                })

                table.$children.forEach(child => {
                    if (child.$options.name.includes('BgColumn')) {
                        while (child.$options.name !== 'BgColumnWrapper' && typeof child !== 'undefined') {
                            child = child.$children[0]
                        }

                        let $parent = newColumns.find(column => {
                            return column.label === child.label
                        })

                        if (this.settings.visibilities[child.label] !== child.renderVisible) {
                            child.renderVisibleValue = this.settings.visibilities[child.label]
                        }

                        if (!child.label || child.label === 'auto') {
                            child.renderVisibleValue = false
                        }

                        if (child._uid !== $parent._uid) {
                            for (let key in $parent.display()) {
                                if (typeof $parent.display(key) !== 'undefined' && child.display(key) != $parent.display(key)) {
                                    child.display(key, $parent.display(key))
                                }
                            }
                        }
                    }
                })
            }
        })

        this.updateSettings(sendSettings)
    },
}
</script>

<template>
    <div>
        <bg-table-tool-bar :site-count="siteCount" :site-columns="siteColumns" :searchValue="query.search.value" @search="handleSearch" @count="handleCount" :settings="settings" :create="create" :object="object" />

        <b-table :data="items" @sort="handleSort" :backend-sorting="true">
            <template slot-scope="props">
                <slot :row="props.row" v-if="!('__isGroup' in props.row)"/>
                <slot name="group" :row="props.row" v-if="'__isGroup' in props.row">default</slot>
                <bg-column-edited :label="$t('Last changes')" sort="UpdatedOn" :collection="props.row" v-if="!('__isGroup' in props.row) && !noChanges" />
                <bg-column :label="$t('Tools')" v-if="!('__isGroup' in props.row)">
                    <div class="buttons is-pulled-right">
                        <slot name="tools" :row="props.row"></slot>
                        <template v-if="stateView">
                            <button @click="editState = props.row" :class="{'button': true, 'is-success': $root.translation(props.row[object + 'I18ns']).StateIdI18n === 1}" type="button" style="border-radius: 50%">
                                <b-icon icon="earth" custom-size="default" style="font-size:22px;margin:1px -11px -2px" />
                            </button>
                        </template>
                        <button v-if="!blankLink && view.length > 0" @click="clickRowView(props.row)" class="button is-success" type="button" style="border-radius: 50%">
                            <b-icon icon="pencil" custom-size="default"/>
                        </button>
                        <a :href="'/#/re-details?replaceObject=' + object + '&replaceId=' + props.row.Id" v-if="blankLink && view.length > 0" class="button is-success" type="button" style="border-radius: 50%" target="_blank">
                            <b-icon icon="pencil" custom-size="default"/>
                        </a>
                        <button v-if="$root.Storage.get('user') && $root.Storage.get('user').roleShortcut === 'admin' && Object.keys(remove).length > 0" @click="clickRowRemove(props)" class="button is-success" type="button" style="border-radius: 50%">
                            <b-icon icon="delete" custom-size="default"/>
                        </button>
                    </div>
                </bg-column>
            </template>

            <section class="section" slot="empty">
                <div class="content has-text-grey has-text-centered">
                    <template v-if="typeof items !== 'undefined'">
                        <p v-if="!itemsLoading">{{ $t('No entries found.') }}</p>
                        <p v-if="itemsLoading">{{ $t('Entries are loading...') }}</p>
                    </template>
                </div>
            </section>
        </b-table>

        <template v-if="stateView">
            <bg-state-modal @close="editState = false" :row="editState" :object="object" :show="editState !== false" />
        </template>

        <div class="level" style="margin-top: 1rem">
            <div class="level-left"></div>
            <div class="level-right">
                <div class="level-item">
                    <b-numberinput class="input--hide--number-buttons" v-model="query.pagination.currentInput" @keyup.enter.native="changePageOnEnter" />
                </div>
                <div class="level-item">
                    <b-pagination
                        :total="(query.pagination.total <= query.pagination.perPage ? 0 : query.pagination.total)"
                        size="is-small"
                        :current="query.pagination.current"
                        :simple="false"
                        :rounded="false"
                        :range-after="2"
                        :range-before="2"
                        :per-page="query.pagination.perPage"
                        @change="handlePage"
                        aria-page-label="Page"
                        aria-next-label="Next page"
                        aria-current-label="Current page"
                        aria-previous-label="Previous page" />
                </div>
            </div>
        </div>
    </div>
</template>
